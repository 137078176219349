@import "@/styles/common";

.home-content-two {
  width: 100%;
  // transform: translateY(-100vh);
  height: 100vh;
  // background: url("https://oss.5rs.me/oss/uploadfe/png/0ce32552a7263b209c02aeac19de8aad.png") no-repeat center center/19.2rem;
  &-container {
    margin: 0 auto;
    width: 10.8rem;
    height: 100vh;
    padding-top: 0.73rem;
    box-sizing: border-box;
    position: relative;
    .nav-menu {
      position: absolute;
      right: 0;
      bottom: 0.66rem;
    }
    /*自定义动画类----顺时针旋转（使用这个动画的时候才设置动画执行时间）*/
    @keyframes changeright {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-360deg);
        transition: all 20s;
      }
    }
    /*自定义动画类----逆时针旋转（使用这个动画的时候才设置动画执行时间）*/
    @keyframes changeleft {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
        transition: all 20s;
      }
    }

    .big {
      width: 6.66rem;
      height: 6.66rem;
      margin: 0 auto;
      opacity: 0;
      // transition: opacity 1.5s ease-in-out;
      position: relative;
      &.show {
        opacity: 1;
        .big-rotate {
          opacity: 1;
        }
        .big-text {
          .big-text-logo,
          .big-text-text {
            opacity: 1;
          }
        }
        .small {
          .rotate,
          .about {
            opacity: 1;
          }
        }
      }
      &.hidden {
        opacity: 1;
        .big-rotate {
          opacity: 0;
        }
        .big-text {
          .big-text-logo,
          .big-text-text {
            opacity: 0;
          }
        }
        .small {
          .rotate,
          .about {
            opacity: 0;
          }
        }
      }
      &.translate {
        transition: opacity 1.5s ease-in-out;
        .big-rotate {
          transform: translateX(-0.4rem);
          opacity: 0;
        }
        .big-text {
          .big-text-logo,
          .big-text-text {
            transform: translateX(0.4rem);
            opacity: 0;
          }
        }
        .small {
          transform: translateX(0.4rem);
          opacity: 0;
        }
      }
      .big-rotate {
        width: 6.66rem;
        opacity: 0;
        transition: opacity 1.5s ease-in-out;
        height: 6.66rem;
        position: absolute;
        background: url("https://oss.5rs.me/oss/uploadfe/png/fc299483c77920faab87abcbebdc18d0.png") no-repeat center;
        background-size: cover;
        animation: changeright 20s linear infinite;
      }
      &-text {
        width: 5.3rem;
        height: 5.3rem;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
        text-align: center;
        box-sizing: border-box;
        padding: 0.95rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: url("https://oss.5rs.me/oss/uploadfe/png/8da9b5725cea11762323aefce0a7436f.png") no-repeat center;
        background-size: cover;
        .mt10 {
          margin: 0 auto;
        }
        &-logo {
          opacity: 0;
          transition: all 1.5s ease-in-out;
          width: 1.4rem;
          height: 1.12rem;
          margin-bottom: 0.74rem !important;
        }
        &-text {
          opacity: 0;
          transition: all 1.5s ease-in-out;
          width: 100%;
          .text-bg-wrapper {
            width: 4.18rem;
            margin: 0 auto;
            line-height: 0.28rem;
            text-align: center;
            font-size: 0.17rem;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #fff;
          }
        }
      }
      .small {
        width: 1.65rem;
        height: 1.65rem;
        border-radius: 50%;
        background: url("https://oss.5rs.me/oss/uploadfe/png/b28b89ac0d7f4b31c9ac5a3118166ef6.png") no-repeat center;
        background-size: cover;
        position: absolute;
        top: 0.55rem;
        right: 0.36rem;
        text-align: center;
        transition: all 1.5s ease-in-out;
        line-height: 1.65rem;
        .rotate {
          transition: all 1.5s ease-in-out;
          opacity: 0;
          width: 1.5rem;
          height: 1.5rem;
          background: url("https://oss.5rs.me/oss/uploadfe/png/0f297210bf56be319b3dee3c9c037061.png") no-repeat center;
          background-size: cover;
          border-radius: 50%;
          position: absolute;
          text-align: center;
          box-sizing: border-box;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          animation: changeleft 20s linear infinite;
        }
        .about {
          transition: all 1.5s ease-in-out;
          margin: 0 auto;
          opacity: 0;
          width: 0.86rem;
          height: 0.46rem;
          object-fit: cover;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.home-content-two-m {
  height: 7.65rem;
  background-size: cover !important;
}
