@import "@/styles/common";

.home-content-three {
  width: 100%;
  height: 100vh;
  position: relative;
  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    content: " ";
    opacity: 0;
    width: 100%;
    height: 100vh;
    background: url("https://oss.5rs.me/oss/uploadfe/png/8b73f4ab68994fc6ef2c410a40599f91.png") center center/19.20rem
      no-repeat;
  }
  &.enterAnimation {
    &::before {
      opacity: 1;
    }
    .home-content-three-container {
      &::after {
        width: 7.6rem;
        height: 5.42rem;
        opacity: 1;
      }
      .bg-area {
        opacity: 1;
      }
      .serve,
      .bg-area-text {
        transform: translateX(0) translateY(0) scale(1);
        opacity: 1;
      }
    }
  }

  &.enterEnd {
    &::before {
      opacity: 1;
    }

    .home-content-three-container {
      .bg-area {
        opacity: 1;
      }
      .serve {
        &:hover {
          animation-play-state: paused;
        }
      }
    }
  }
  &.leaveStart {
    &::before {
      opacity: 0;
    }
    .home-content-three-container {
      .bg-area {
        opacity: 0;
      }
      .serve,
      .bg-area-text {
        transition: all 1s linear;
        transform: translateX(0.8rem) translateY(-0.4rem) scale(0);
        opacity: 0;
      }
    }
  }

  &-container {
    margin: 0 auto;
    width: 10.8rem;
    height: 7.65rem;
    z-index: 1;
    opacity: 1;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      top: 0.7rem;
      right: -0.3rem;
      opacity: 0;
      width: 0rem;
      height: 0rem;
      transition: all 1.5s ease;
      background: url("https://oss.5rs.me/oss/uploadfe/png/3fb277ca77bcb3b68158c81dffabf7a9.png") top right/100% 100%
        no-repeat;
    }
    .serve {
      opacity: 0;
      transition: all 1.5s ease;
      position: absolute;
      display: block;
      z-index: 1;
      > img {
        width: 100%;
        height: 100%;
        transition: all 0.3s linear;
        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .serve:hover {
      animation-play-state: paused;
    }

    .bg-area {
      position: absolute;
      cursor: pointer;
      z-index: 1;
      opacity: 0;
      right: -4.2rem;
      width: 8.94rem;
      height: 5.57rem;
      object-fit: cover;
      > img {
        width: 100%;
        height: 100%;
      }

      &-text {
        transform: translateX(0.4rem);
        z-index: 1;
        opacity: 0;
        position: absolute;
        width: 1.36rem;
        height: 1.59rem;
        top: 1.06rem;
        right: 0;
        transition: all 1.5s;
        object-fit: cover;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .nowbook {
      width: 1.6rem;
      height: 1.6rem;
      top: 0.97rem;
      left: 2.86rem;
      animation: nowbook 2.7s linear infinite;
    }

    .raysdata {
      width: 1.4rem;
      height: 1.4rem;
      top: 2.75rem;
      left: 2.25rem;
      animation: nowbook 2.8s linear infinite;
    }

    .publish {
      width: 1.3rem;
      height: 1.3rem;
      top: 3.15rem;
      left: 4.1rem;
      animation: publish 3.1s linear infinite;
    }

    .edit {
      width: 1.6rem;
      height: 1.6rem;
      top: 4.7rem;
      left: 3.7rem;
      animation: edit 3.2s linear infinite;
    }

    .ruiknown {
      width: 1.16rem;
      height: 1.16rem;
      top: 4.5rem;
      left: 5.65rem;
      animation: ruiknown 3s linear infinite;
    }

    .sale {
      width: 1.3rem;
      height: 1.3rem;
      top: 5.7rem;
      left: 6.15rem;
      animation: metaverse 2.9s linear infinite;
    }

    .metaverse {
      width: 1.16rem;
      height: 1.16rem;
      top: 5.8rem;
      left: 7.7rem;
      animation: metaverse 3.1s linear infinite;
    }

    .nav-menu {
      position: absolute;
      right: 0;
      bottom: 0.66rem;
    }

    @keyframes nowbook {
      0%,
      100% {
        transform: translate(0, 0);
      }
      25%,
      75% {
        transform: translate(0.03rem, -0.01rem);
      }
      50% {
        transform: translate(0.08rem, -0.03rem);
      }
    }

    @keyframes publish {
      0%,
      100% {
        transform: translate(0, 0);
      }
      25%,
      75% {
        transform: translate(0.03rem, -0.01rem);
      }
      50% {
        transform: translate(0.1rem, -0.05rem);
      }
    }

    @keyframes edit {
      0%,
      100% {
        transform: translate(0, 0);
      }
      25%,
      75% {
        transform: translate(0.04rem, -0.01rem);
      }
      50% {
        transform: translate(0.1rem, -0.05rem);
      }
    }

    @keyframes ruiknown {
      0%,
      100% {
        transform: translate(0, 0);
      }
      25%,
      75% {
        transform: translate(0.03rem, -0.02rem);
      }
      50% {
        transform: translate(0.08rem, -0.06rem);
      }
    }

    @keyframes metaverse {
      0%,
      100% {
        transform: translate(0, 0);
      }
      25%,
      75% {
        transform: translate(0.03rem, -0.03rem);
      }
      50% {
        transform: translate(0.08rem, -0.08rem);
      }
    }
  }
}
.home-content-three-m {
  height: unset;
  &::before {
    height: 7.65rem;
  }
}
