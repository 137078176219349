.move-animation-page {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 11;
  overflow: hidden;
  &.hidden {
    z-index: -1;
  }
  .move-animation-content {
    margin: 0 auto;
    width: 10.8rem;
    height: 7.65rem;
    box-sizing: border-box;
    padding-top: 0.4rem;
    position: relative;
    .texts {
      position: absolute;
      width: 1.36rem;
      height: 1.59rem;
      top: 1.06rem;
      right: 0;
      background: url("https://oss.5rs.me/oss/uploadfe/png/8f039e78a19df2d8041cd1e795007f5b.png") top left/100% 100%
        no-repeat;
    }
    .main-item {
      position: absolute;
      z-index: 10;
      opacity: 1;
      // background: url('https://oss.5rs.me/oss/uploadfe/png/aca49d5912b92639e37eb1c142580c88.png') bottom left/8.94rem 5.9rem no-repeat;
      border-radius: 50%;
      width: 13rem;
      height: 13rem;
      transform: translate3d(5.2rem, -6.2rem, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      // top: -5.8rem;
      // right: -6.15rem;
      transition: opacity 0.5s linear 0.5s;
      // z-index: 100;
      &.translate {
        opacity: 0;
        // z-index: 1;
      }
      &.down {
        transition: opacity 0.2s linear 0s;
      }
    }
    .item-left {
      width: 2.69rem;
      height: 2.69rem;
      position: absolute;
      left: -2.45rem;
      bottom: 0.37rem;
      z-index: 10;
      background: linear-gradient(to left, #2082c1, #124089);
      border-radius: 50%;
    }
    .item-right {
      width: 1.12rem;
      height: 1.12rem;
      // background: url('https://oss.5rs.me/oss/uploadfe/png/c32ce641fd7b9cc439861f2a30c7fe26.png');
      border-radius: 50%;
      background: linear-gradient(to left, #2082c1, #124089);
      background-size: 100% 100%;
      transform: translate3d(6.1rem, 4.86rem, 0);
      z-index: 12;
      display: flex;
      justify-content: center;
      align-items: center;
      .count-text {
        width: 0.6rem;
        height: 0.63rem;
        background: url(https://oss.5rs.me/oss/uploadfe/png/719473131fb6d0f64a98f41d3957035a.png) center center/100%
          100% no-repeat;
      }
    }
    .item-center {
      position: relative;
      z-index: 1;
      width: 0.36rem;
      height: 0.36rem;
      // border: 1px solid green;
      transform: translate3d(5.49rem, 1.8rem, 0);
      background: #259ed6;
      border-radius: 50%;
      // left: 5.49rem;
      // top: 3.32rem;
      z-index: 12;
    }
  }
}
